import Repository from './repository'

const resource = '/v1/configuracoes/instrutores'

export default {
  buscaInstrutor (idInstrutor) {
    return Repository.get(`${resource}/${idInstrutor}`)
  },
  listarInstrutores () {
    return Repository.get(`${resource}`)
  },
  salvarInstrutor (instrutor) {
    return Repository.post(`${resource}`, instrutor)
  },
  atualizarInstrutor (idInstrutor, instrutor) {
    return Repository.put(`${resource}/${idInstrutor}`, instrutor)
  },
  deletarInstrutor (id) {
    return Repository.delete(`${resource}/${id}`)
  }
}
