<template>
  <div class="container-fluid card-body">
    <Load :isLoading="isLoading" />
    <div class="row">
      <div class="col">
        <h1>Instrutores</h1>
      </div>
      <div class="col">
        <router-link to="instrutores/cadastro" v-if="permissoes >= 2">
          <button type="button" class="btn btn-primary float-right btn-hover btn-dark-color">CADASTRAR</button>
        </router-link>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <MyTable
          :searchable="true"
          :data="instrutores"
          :columns="columns"
          :itemsPerPage="10">
          <template #column="{item, column}">
            <span v-if="column.field == 'actions'">
              <i class="fas fa-edit mr-3 btn btn-warning" title="Editar instrutor" @click="editarItem(item.id)" v-if="permissoes > 1"></i>
              <i class="fas fa-trash-alt mr-3 btn btn-danger" title="Deletar instrutor" @click="deletarItem(item)" v-if="permissoes > 2"></i>
            </span>
            <span v-else>
              {{item[column.field]}}
            </span>
          </template>
        </MyTable>
      </div>
    </div>
  </div>
</template>

<script>
import MyTable from './../../../uicomponents/MyTable'
import instrutoresRepository from './../../../services/api/instrutoresRepository'
export default {
  name: 'Empresas',
  components: {
    MyTable
  },
  data(){
    return {
      isLoading: false,
      permissoes: [],
      columns: [
        {
          label: 'Nome',
          field: 'nome',
          sortable: 'nome'
        },
        {
          label: 'Email',
          field: 'email_profissional',
          sortable: ''
        },
        {
          label: 'Telefone',
          field: 'telefone',
          sortable: ''
        },
        {
          label: 'Ações',
          field: 'actions',
          sortable: ''
        }
      ],
      instrutores: []
    }
  },
  created () {
    this.fetch()
    this.permissoes = this.$store.getters.permissoes['Treinamento']['Instrutores']
  },
  methods: {
    editarItem (id) {
      this.$router.push({ name: 'InstrutoresCadastro', query: { id: id } })
    },
    deletarItem (instrutor) {
      let _this = this
      this.$swal({
        icon: 'warning',
        title: 'Remover deficiência',
        text: `Deseja deletar a ${instrutor.nome}?`,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim, tenho certeza!',
        cancelButtonText: 'Não',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.deletarInstrutor(instrutor)
        }
      }).then(function(result) {
        if (result.value) {
          _this.$swal({
            icon: 'success',
            text: 'Registro removido com sucesso!'
          }).then(() => {
            _this.fetch()
          })
          
        } else {
          console.log('FAIL')
        }
      })
    },
    deletarInstrutor (instrutor) {
      this.isLoading = true
      instrutoresRepository.deletarInstrutor(instrutor.id).then((response) => {
        this.isLoading = false
        return response.data['success']
      }).catch(() => {
        this.isLoading = false
        return false
      })
    },
    fetch () {
      this.isLoading = true
      instrutoresRepository.listarInstrutores().then(response => {
        this.instrutores = response.data['data']
        this.isLoading = false
      }).catch( () => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>

</style>